import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const AUTH_LOGIN_GATEWAY = new InjectionToken<AuthLoginGateway>(
  'auth-login-gateway',
);

export interface AuthLoginGateway {
  checkInLogin(): Observable<unknown>;
}
