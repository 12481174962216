import { Inject, Injectable } from '@angular/core';
import { AUTH_LOGIN_GATEWAY, AuthLoginGateway } from './auth-login.gateway';

@Injectable({ providedIn: 'root' })
export class AuthLoginController {
  constructor(
    @Inject(AUTH_LOGIN_GATEWAY)
    private readonly gateway: AuthLoginGateway,
  ) {}

  checkInLogin() {
    return this.gateway.checkInLogin();
  }
}
