import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Layout } from '../layout/layout.enum';
import { IPage } from '../page/page.interface';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { EMPTY, switchMap, tap } from 'rxjs';
import { JwtStorage } from '@prlw/core/auth/jwt/jwt.storage';
import { UserDataService } from '@prlw/core/customers/oidc/user-data.service';
import { AuthLoginController } from '@prlw/core/auth/auth-login/auth-login.controller';
import { Router } from '@angular/router';

@Component({
  selector: 'prlw-unauthorized',
  standalone: true,
  imports: [],
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnauthorizedPage implements IPage, OnInit {
  public readonly headerTitle = 'Авторизация';
  public readonly pageTitle = 'Авторизация';
  public readonly layout = Layout.Basic;

  constructor(
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly jwtStorage: JwtStorage,
    private readonly userDataService: UserDataService,
    private readonly authLoginController: AuthLoginController,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.oidcSecurityService
      .checkAuth()
      .pipe(
        switchMap(({ isAuthenticated, idToken, userData }) => {
          if (isAuthenticated) {
            this.jwtStorage.updateTokens(idToken, idToken);
            this.userDataService.setUserData(userData);
            return this.authLoginController.checkInLogin().pipe(
              tap(() => {
                this.router.navigate(['/']);
              }),
            );
          }
          this.oidcSecurityService.authorize();
          return EMPTY;
        }),
      )
      .subscribe();
  }
}
