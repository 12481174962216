import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SIGN_IN_BY_LOGIN_GATEWAY } from '@prlw/core/auth/sign-in-by-login/sign-in-by-login.gateway';
import { AuthGateway } from '@prlw/data/auth/auth.gateway';
import { JWT_GATEWAY } from '@prlw/core/auth/jwt/jwt.gateway';
import { RECOVERY_PASSWORD_GATEWAY } from '@prlw/core/auth/password-recovery/password-recovery-gateway.interface';
import {
  AUTH_DATA_CONFIG,
  AuthDataConfig,
} from '@prlw/data/auth/auth-data.config';
import { SIGN_IN_BY_PHONE_GATEWAY } from '@prlw/core/auth/sign-in-by-phone/sign-in-by-phone.gateway';
import { AUTH_LOGIN_GATEWAY } from '@prlw/core/auth/auth-login/auth-login.gateway';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: SIGN_IN_BY_LOGIN_GATEWAY,
      useClass: AuthGateway,
    },
    {
      provide: SIGN_IN_BY_PHONE_GATEWAY,
      useClass: AuthGateway,
    },
    {
      provide: AUTH_LOGIN_GATEWAY,
      useClass: AuthGateway,
    },
    {
      provide: JWT_GATEWAY,
      useClass: AuthGateway,
    },
    {
      provide: RECOVERY_PASSWORD_GATEWAY,
      useClass: AuthGateway,
    },
  ],
})
export class AuthDataModule {
  public static forRoot(
    config: AuthDataConfig,
  ): ModuleWithProviders<AuthDataModule> {
    return {
      ngModule: AuthDataModule,
      providers: [{ provide: AUTH_DATA_CONFIG, useValue: config }],
    };
  }
}
