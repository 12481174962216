import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStateProvider } from '@prlw/core/auth/auth-state.provider';
import { map } from 'rxjs/operators';

const REDIRECT_TO = '/unauthorized';

@Injectable({
  providedIn: 'root',
})
export class DemoOrAboveGuard {
  constructor(
    private readonly authStateProvider: AuthStateProvider,
    private readonly router: Router,
  ) {}

  public canActivate(): Observable<true | UrlTree> {
    return this.authStateProvider.authLevel$.pipe(
      map((level) => level.isDemoOrAbove || this.router.parseUrl(REDIRECT_TO)),
    );
  }
}
