import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { TUserData } from './types';

@Injectable({ providedIn: 'root' })
export class UserDataService {
  userData$ = new ReplaySubject<TUserData>();

  setUserData(value: TUserData) {
    this.userData$.next(value);
  }
}
