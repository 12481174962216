<prlw-common-pane *ngIf="profile">
  <div class="container">
    @if (xsmall$ | async) {
      <div class="flex-col">
        <div class="text bold">{{ profile.organization?.name }}</div>
        <div class="text">{{ profile.fullName }}</div>
      </div>
    } @else {
      <span *ngIf="profile.organization; else noOrganization">
        {{ profile.organization.name }} ({{ profile.fullName }})
      </span>
    }
    <ng-template #noOrganization>
      <span> {{ profile.fullName }} </span>
    </ng-template>
  </div>

  <button
    prlwDemoAccessOverlay
    class="status-button profile"
    aria-label="Профиль пользователя"
    (click)="eventProfileButtonClick.emit()"
    prlwResponsive
  >
    <prlw-status-for-icon></prlw-status-for-icon>
    <prlw-icon name="profile" class="profile-icon"></prlw-icon>
  </button>
</prlw-common-pane>
