import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccessToken } from '@prlw/core/auth/jwt/access-token';
import { RefreshToken } from '@prlw/core/auth/jwt/refresh-token';

export const REFRESH_TOKEN_LS_KEY = 'prlw-refresh-token';

export type AuthTokens = {
  accessToken: AccessToken | null;
  refreshToken: RefreshToken | null;
};

@Injectable({ providedIn: 'root' })
export class JwtStorage {
  private readonly _tokens$: BehaviorSubject<AuthTokens>;

  constructor() {
    this._tokens$ = new BehaviorSubject<AuthTokens>({
      accessToken: null,
      refreshToken: null,
    });
  }

  public get tokens$(): Observable<AuthTokens> {
    return this._tokens$.asObservable();
  }

  public dropAccessToken(): void {
    const { refreshToken } = this._tokens$.getValue();
    this._tokens$.next({
      accessToken: null,
      refreshToken,
    });
  }

  public dropTokens(): void {
    this._tokens$.next({ accessToken: null, refreshToken: null });
  }

  public updateTokens(
    accessToken: AccessToken,
    refreshToken: RefreshToken,
  ): void {
    this._tokens$.next({ accessToken, refreshToken });
  }
}
