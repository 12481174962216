import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Profile } from '@prlw/core/profile/profile.entity';
import { Router } from '@angular/router';
import { LogoutController } from '@prlw/core/auth/logout/logout.controller';

@Component({
  selector: 'prlw-demo-access-ui',
  templateUrl: './demo-access.component.html',
  styleUrls: ['./demo-access.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemoAccessComponent {
  @Input() public profile!: Profile;
  @Output() public readonly closeModal = new EventEmitter<void>();

  constructor(
    private readonly router: Router,
    private readonly logoutController: LogoutController,
  ) {}

  public mapping: { [k: string]: string } = {
    one: '# день',
    few: '# дня',
    other: '# дней',
  };

  public goToProfilePage(): void {
    this.closeModal.emit();
    this.router.navigate(['/profile']);
  }

  public logout(): void {
    this.logoutController.logout();
    this.closeModal.emit();
  }
}
