import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { LayoutMinimalComponent } from './minimal/layout-minimal.component';
import { LayoutPrimaryComponent } from './primary/layout-primary.component';
import { HeaderModule } from '@prlw/common/header/header.module';
import { NavbarModule } from '@prlw/common/navbar/navbar.module';
import { ResponsiveDirective } from '@prlw/libs/responsive/responsive.directive';
import { MobileFooterContainer } from '../mobile-footer/mobile-footer.container';
import { LayoutBasicComponent } from './basic/layout-basic.component';

@NgModule({
  declarations: [
    LayoutComponent,
    LayoutBasicComponent,
    LayoutMinimalComponent,
    LayoutPrimaryComponent,
  ],
  imports: [
    CommonModule,
    HeaderModule,
    NavbarModule,
    ResponsiveDirective,
    MobileFooterContainer,
  ],
  exports: [LayoutComponent],
})
export class LayoutModule {}
