<div class="container" prlwResponsive>
  <header class="header" prlwResponsive>
    <prlw-header-primary></prlw-header-primary>
  </header>

  <aside class="sidebar">
    <prlw-navbar></prlw-navbar>
  </aside>

  <main class="main" prlwResponsive>
    <ng-content></ng-content>
  </main>
</div>

<prlw-mobile-footer *ngIf="xsmall$ | async"></prlw-mobile-footer>
