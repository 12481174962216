import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DemoOrAboveGuard } from '@prlw/infrastructure/auth/demo-or-above.guard';
import { UnauthorizedPage } from './common/unauthorized/unauthorized.page';

const routes: Routes = [
  {
    path: 'unauthorized',
    component: UnauthorizedPage,
  },
  {
    path: 'futures',
    loadChildren: () =>
      import('./features/futures/futures.module').then((m) => m.FuturesModule),
    canActivate: [DemoOrAboveGuard],
  },
  {
    path: 'monitor',
    loadChildren: () =>
      import('./features/monitor/monitor.module').then((m) => m.MonitorModule),
    canActivate: [DemoOrAboveGuard],
  },
  {
    path: 'contracts',
    loadChildren: () =>
      import('./features/contracts/contracts.module').then(
        (m) => m.ContractsModule,
      ),
    canActivate: [DemoOrAboveGuard],
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./features/registration/registration.module').then(
        (m) => m.RegistrationModule,
      ),
  },
  {
    path: 'password-recovery',
    loadChildren: () =>
      import('./features/password-recovery/password-recovery.module').then(
        (m) => m.PasswordRecoveryModule,
      ),
  },
  {
    path: 'deals',
    loadChildren: () =>
      import('./features/deals/deals.module').then((m) => m.DealsModule),
    canActivate: [DemoOrAboveGuard],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./features/profile/profile-ui.module').then(
        (m) => m.ProfileUiModule,
      ),
    canActivate: [DemoOrAboveGuard],
  },
  {
    path: 'analytics',
    loadChildren: () =>
      import('./features/analytics-charts/analytics.module').then(
        (m) => m.AnalyticsModule,
      ),
    canActivate: [DemoOrAboveGuard],
  },
  {
    path: 'region',
    loadChildren: () =>
      import('./features/small-wholesale/small-wholesale.module').then(
        (m) => m.SmallWholesaleModule,
      ),
  },
  {
    path: 'buy-license',
    loadChildren: () =>
      import('./features/buy-license/buy-license.module').then(
        (m) => m.BuyLicenseModule,
      ),
    canActivate: [DemoOrAboveGuard],
  },
  {
    path: '**',
    redirectTo: 'monitor',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
