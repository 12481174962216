import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResponsiveService } from '@prlw/libs/responsive/responsive.service';

@Component({
  selector: 'prlw-layout-primary',
  templateUrl: './layout-primary.component.html',
  styleUrls: ['./layout-primary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutPrimaryComponent {
  constructor(private readonly responsiveService: ResponsiveService) {}

  xsmall$ = this.responsiveService.xsmall$;
}
